<template>
  <div class="editDialog">
    <el-button type="primary" icon="el-icon-edit" size="mini" @click="showEditDialog(id)">编辑</el-button>
    <el-dialog
      title="修改服务条款"
      :visible.sync="editDialogVisible"
      width="50%"
      @close="editDialogClose">
      <el-form :model="editTermForm" :rules="editTermFormRules" ref="editTermFormRef" label-width="90px">
        <el-form-item label="条款标题" prop="title">
          <el-input v-model="editTermForm.title" ></el-input>
        </el-form-item>
        <el-form-item label="条款详情" prop="code">
          <el-input v-model="editTermForm.desc"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="editDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="updateTermInfo">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>

import {
  GetSaleTermRequest,
  UpdateSaleTermRequest
} from '../../../network/goods'

export default {
  name: 'TermEditDialog',
  props: {
    id: {
      type: Number,
      default: 0
    }
  },
  data () {
    return {
      editDialogVisible: false,
      editTermForm: {
        title: '',
        desc: ''
      },
      editTermFormRules: {
        title: [
          {
            required: true,
            message: '请输入条款标题'
          }
        ]
      }
    }
  },
  methods: {
    // 展示编辑用户的对话框，并获取当前用户的数据
    showEditDialog (termId) {
      this.editDialogVisible = true
      GetSaleTermRequest(termId).then(res => {
        const result = res.data
        if (res.status !== 200) {
          return this.alertMessage('获取条款信息失败', 'error')
        }
        this.editTermForm = result.data
        console.log('showEditDialog editTermForm is:', this.editTermForm)
      })
    },

    // 监听编辑用户的对话框的关闭事件
    editDialogClose () {
      this.$refs.editTermFormRef.resetFields()
    },

    // 点击编辑店铺的“确定”按钮
    updateTermInfo () {
      this.$refs.editTermFormRef.validate(valid => {
        if (!valid) return
        console.log('update Term value:', this.editTermForm)
        UpdateSaleTermRequest(this.editTermForm).then(res => {
          // 更新失败
          if (res.status !== 200) {
            return this.alertMessage('更新条款信息失败', 'error')
          }
          // 提示更新成功
          this.alertMessage('更新条款信息成功', 'success')
          // 关闭对话框
          this.editDialogVisible = false
          // 重新获取店铺列表
          this.$emit('term-list')
        })
      })
    }
  }
}
</script>

<style scoped>
.editDialog {
  display: inline-block;
  margin-right: 5px;
}
</style>
