<template>
  <div>
    <!-- 搜索与添加按钮 -->
    <el-row :gutter="20">
      <el-col :span="8">
        <!-- 搜索与添加区域 -->
        <el-input placeholder="条款标题" v-model="listQuery.title" clearable @clear="search">
          <el-button slot="append" icon="el-icon-search" @click="search"></el-button>
        </el-input>
      </el-col>
      <el-col :span="4">
        <el-button type="primary" @click="addDialogVisible = true">添加条款</el-button>
      </el-col>
    </el-row>

    <!-- 添加站点的对话框 -->
    <el-dialog
      title="添加条款"
      :visible.sync="addDialogVisible"
      width="50%"
      @close="addDialogClose">
      <el-form :model="addTermForm" :rules="addTermFormRules" ref="addTermFormRef" label-width="90px">
        <el-form-item label="条款标题" prop="title">
          <el-input v-model="addTermForm.title"></el-input>
        </el-form-item>
        <el-form-item label="条款描述" prop="desc">
          <el-input v-model="addTermForm.desc"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="cancelAddTerm">取 消</el-button>
        <el-button type="primary" @click="addTerm">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>

import { AddSaleTermRequest } from '../../../network/goods'

export default {
  name: 'TermSearchAndAdd',
  props: {
    listQuery: {
      type: Object,
      default () {
        return {
          title: '',
          page: 1, // 当前页
          size: 10 // 每页显示多少条数据
        }
      }
    }
  },
  data () {
    return {
      addDialogVisible: false,
      // 添加店铺表单的数据
      addTermForm: {
        title: '',
        desc: ''
      },
      addTermFormRules: {
        title: [
          {
            required: true,
            message: '请输入店铺名称',
            trigger: 'blur'
          }
        ]
      }
    }
  },
  methods: {
    // 点击搜索按钮时，通知外部组件重新获取商铺列表
    search () {
      this.$emit('term-list')
    },

    // 监听弹框的关闭事件
    addDialogClose () {
      console.log('addDialogClose')
      this.$refs.addTermFormRef.resetFields()
    },
    cancelAddTerm () {
      this.addDialogVisible = false
    },
    addTerm () {
      this.$refs.addTermFormRef.validate(valid => {
        if (!valid) return
        AddSaleTermRequest(this.addTermForm).then(res => {
          if (res.status !== 200) {
            return this.alertMessage('添加条款失败', 'error')
          }
          this.alertMessage('添加条款成功', 'success')
          this.addDialogVisible = false
          this.$emit('term-list')
        })
      })
    }
  }
}
</script>

<style scoped>
</style>
