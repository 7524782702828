<template>
  <el-table class="custom-term-table" :data="termList" border stripe>
    <!-- 基本信息 -->
    <el-table-column width="50" type="index" label="#"></el-table-column>
    <el-table-column width="200" prop="title" label="条款标题"></el-table-column>
    <el-table-column prop="desc" label="条款详情"></el-table-column>
    <el-table-column label="操作" width="200px" class="btns">
      <template slot-scope="scope">
        <term-edit-dialog :id="scope.row.id" @term-list="updateTermList" />
        <term-remove-dialog :id="scope.row.id" @term-list="updateTermList" />
      </template>
    </el-table-column>
  </el-table>
</template>

<script>

import TermEditDialog from './TermEditDialog'
import TermRemoveDialog from './TermRemoveDialog'

export default {
  name: 'TermTable',
  components: {
    TermEditDialog,
    TermRemoveDialog
  },
  filters: {},
  props: {
    termList: {
      type: Array,
      default () {
        return []
      }
    },
    listQuery: {
      type: Object,
      default () {
        return {
          title: '',
          page: 1,
          size: 10
        }
      }
    }
  },
  data () {
    return {}
  },
  methods: {
    // 监听子组件发射的事件，获得最新的termList和total信息
    updateTermList () {
      this.$emit('term-list')
    }
  }
}
</script>

<style scoped>
.custom-term-table {
  margin-top: 20px;
}
</style>
