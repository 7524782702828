import { render, staticRenderFns } from "./TermTable.vue?vue&type=template&id=85dabd06&scoped=true"
import script from "./TermTable.vue?vue&type=script&lang=js"
export * from "./TermTable.vue?vue&type=script&lang=js"
import style0 from "./TermTable.vue?vue&type=style&index=0&id=85dabd06&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "85dabd06",
  null
  
)

export default component.exports