<template>
  <div>
    <!-- 顶部面包屑导航 -->
    <breadcrumb-nav>
      <template v-slot:firstMenu>商品管理</template>
      <template v-slot:secondMenu>服务保障</template>
    </breadcrumb-nav>
    <!-- 卡片视图-->
    <el-card class="box-card">
      <!-- 顶部搜索与添加按钮 -->
      <term-search-and-add :list-query="listQuery" @term-list="updateTermList"/>

      <!-- 展示的表格 -->
      <term-table :term-list="termList" :list-query="listQuery" @term-list="updateTermList"/>

      <!-- 分页 -->
      <term-pagination :list-query="listQuery" :total="total" @page-size-change="handleSizeChange"
                       @current-page-change="handleCurrentChange"/>
    </el-card>
  </div>
</template>

<script>

import BreadcrumbNav from '../common/BreadcrumbNav'
import TermSearchAndAdd from './saleTermChildComponents/TermSearchAndAdd'
import TermTable from './saleTermChildComponents/TermTable'
import TermPagination from './saleTermChildComponents/TermPagination'
import { ListSaleTermRequest } from '../../network/goods'

export default {
  name: 'Term',
  components: {
    BreadcrumbNav,
    TermSearchAndAdd,
    TermTable,
    TermPagination
  },
  data () {
    return {
      listQuery: {
        title: '',
        page: 1,
        size: 10
      },
      termList: [],
      total: 0
    }
  },

  created () {
    this.getSaleTermList()
  },
  methods: {
    // 监听pageSize改变的事件，并发送新的网络请求
    handleSizeChange (newSize) {
      this.listQuery.size = newSize
      this.getSaleTermList()
    },

    // 监听页码改变的事件，并发送新的网络请求
    handleCurrentChange (newPage) {
      this.listQuery.page = newPage
      this.getSaleTermList()
    },

    getSaleTermList () {
      ListSaleTermRequest(this.listQuery).then(res => {
        const result = res.data
        if (res.status !== 200) {
          return this.alertMessage('获取条款列表获取失败', 'error')
        }
        this.termList = result.data.list
        this.total = result.data.total
      })
    },

    // 监听子组件发射的事件，获得最新的userList和total信息
    updateTermList () {
      this.getSaleTermList()
    }
  }
}
</script>

<style scoped>

</style>
